
.receipt-card {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 0.1rem solid;
  border-radius: 1rem;
  flex-grow: 1;
  min-width: 10rem;
  max-width: 15rem;

  display: flex;
  flex-direction: column;
}

.receipt-card__header {
  display: grid;
  grid-template-columns: 100% -15%;
  grid-template-rows: 100%;
  margin-bottom: 0.1rem;
}

.receipt-card__title {
  grid-column: 1;
  grid-row: 1;
  font-size: 1.25rem;
}

.receipt-card__check_container {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}

.receipt-card__check {
  cursor: pointer;
  fill-opacity: 0.54;
  position: absolute;
  top: 0;
  right: 0;
}

.receipt-card__content {
  margin-bottom: 0.1rem;
}

.receipt-card__price {
  font-size: 1.5rem;
  text-align: center;
}

.receipt-card__action-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.receipt-card__bottom {
  margin-top: auto;
}




.receipt-container {
  border: 0.1rem solid;
  border-radius: 1rem;
  margin-bottom: 2rem;
  padding: 0.5rem;
}

.receipt-container label {
  font-weight: 600;
  padding-right: 0.5rem;
}  
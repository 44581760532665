.line-separator {
  border-top: 0.1rem solid;
  padding-bottom: 1rem;
}

.top-padding {
  padding-top: 2rem;
}

.action-top-bar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
}
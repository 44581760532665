@media print {
  .no-printable {
    display: none !important;
  }
}

:root {
  font-size: 12px;
}

.rootApp {
}

main {
  background: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 5rem;
}

.bottom-app-bar {
  background: rgb(245, 245, 245);

  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  bottom: 0;
  left: 0;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  
  display: flex;
  justify-content: space-evenly
}

.bottom-app-bar_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.bottom-app-bar_icon i {
  font-size: 1.2rem;
}


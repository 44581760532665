
.receipts-by-month__header {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: 100%;
}

.receipts-by-month__header__left {
  grid-column: 1;
  grid-row: 1;
}

.receipts-by-month__header__left i {
  cursor: pointer;
}

.receipts-by-month__header__right {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: row-reverse;
}

.receipts-by-month__header__right i {
  padding: 1rem;
  cursor: pointer;
}

.receipts-by-month__title {
  font-size: 1.5rem;
  padding-left: .5rem;
}
.receipts-by-month__details {
  font-size: 1rem;
  padding-left: 1rem;
}

.receipts-by-month__menu {
}

.receipts-by-month__list {
  display: flex;
  flex-wrap: wrap;
  /* padding: 1rem; */
}



